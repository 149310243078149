// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start();
require("turbolinks").start();
require("@rails/activestorage").start();
require("channels");

import "../styles/application.css";

import $ from "jquery";
import MicroModal from "micromodal";

global.$ = $;

$(document).on("turbolinks:load", () => {
  global.$.ajaxSetup({
    headers: {
      "X-CSRF-Token": $('meta[name="csrf-token"]').attr("content"),
    },
  });

  MicroModal.init();

  $(".toggle-switch").on("click", (e) => {
    let onToggleAttr = $(e.currentTarget).attr("attr-toggle-on");
    let offToggleAttr = $(e.currentTarget).attr("attr-toggle-off");

    if ($(e.currentTarget).hasClass("activated-toggle")) {
      $(e.currentTarget).removeClass("activated-toggle");
      $("[attr-toggle-group=" + onToggleAttr + "]").addClass("hidden");
      $("[attr-toggle-group=" + offToggleAttr + "]").removeClass("hidden");
    } else {
      $(e.currentTarget).addClass("activated-toggle");
      $("[attr-toggle-group=" + onToggleAttr + "]").removeClass("hidden");
      $("[attr-toggle-group=" + offToggleAttr + "]").addClass("hidden");
    }
  });

  $(".toggle-switch").each(function (index) {
    if ($(this).attr("attr-toggle-state") == "on") $(this).trigger("click");
  });

  let outsideClickWatch = function (e) {
    var container = $("#user-menu-container");

    // if the target of the click isn't the container nor a descendant of the container
    if (!container.is(e.target) && container.find(e.target).length === 0) {
      $("#user-dropdown-menu").hide();
      $(document).off(".hideUserMenu");
    }
  };

  $("#user-dropdown-anchor").on("click", (e) => {
    if ($("#user-dropdown-menu").is(":visible")) {
      $("#user-dropdown-menu").hide();
      $(document).off(".hideUserMenu");
    } else {
      $("#user-dropdown-menu")
        .css("opacity", 0)
        .slideDown(200)
        .animate({ opacity: 1 }, { queue: false, duration: 200 });
      $(document).on("mouseup.hideUserMenu", outsideClickWatch);
    }
  });

  $(".image-input").on("change", (e) => {
    // let preview = $(e.currentTarget).closest(".image-input-preview");
    let preview = $(".image-input-preview");

    if (e.currentTarget.files && e.currentTarget.files[0]) {
      var reader = new FileReader();
      reader.onload = function (e) {
        preview.attr("src", e.target.result);
      };
      reader.readAsDataURL(e.currentTarget.files[0]);
    }
  });

  let loading_overlay = $(
    '<div class="absolute min-h-full min-w-full bg-black opacity-75 -mt-7 -mx-4 text-center py-2 rounded-md"><i class="fas fa-spinner-third fa-spin"></i></div>'
  );

  $("form").on("submit", (e) => {
    let lockableSubmitButton = $(e.currentTarget).find(".lockable-submit");

    if (lockableSubmitButton.attr("disabled") !== true) {
      lockableSubmitButton
        .attr("disabled", true)
        .addClass("cursor-wait")
        .append(loading_overlay);
    }

    return true;
  });

  $("#upload-photo").on("change", function () {
    $("#upload-photo-notice").show();
  });

  $(document).on("submit", "form.gps-required-form", function (e) {
    let formObj = $(this);
    let latLonInput = formObj.find(".lat-lon");

    if (latLonInput.val() == "") {
      e.preventDefault();
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          function (position) {
            console.log(position);
            var latitude = position.coords.latitude;
            var longitude = position.coords.longitude;
            formObj.find(".lat-lon").val(`Lat: ${latitude} Lon: ${longitude}`);
            formObj.trigger("submit");
          },
          function error(msg) {
            alert("Please enable your GPS position feature.");
            console.log(msg);
          },
          { enableHighAccuracy: true }
        );
      } else {
        alert("Geolocation API is not supported in your browser.");
      }
    }

    return true;
  });

  $(".auto-map").each(function () {
    let current_map = $(this);
    var latLonSpot = {
      lat: parseFloat(current_map.attr("data-lat")),
      lng: parseFloat(current_map.attr("data-lon")),
    };
    var map = new google.maps.Map(current_map[0], {
      zoom: 11,
      center: latLonSpot,
    });
    new google.maps.Marker({ position: latLonSpot, map: map });
  });
  // $(".airtable-embed").on("load", function () {
  //   let head = $(this).contents().find("head");
  //   debugger;
  //   alert("loaded!");
  //   let css = "<style>a { display: none !important; } </style>";
  //   $(head).append(css);
  // });
});
// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
// Support component names relative to this directory:
